import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export function useCreateShowRedirect() {
  const { query, pathname, asPath, replace } = useRouter();

  useEffect(() => {
    replace(
      {
        pathname,
        query: {
          ...query,
          disableModalAnimation: true,
          createShow: true
        }
      },
      asPath,
      {
        shallow: true
      }
    );
  }, []);
}

export function useShowModalRoutes(shopSlug?: string) {
  const { query, push, pathname } = useRouter();

  const baseShopPath = `/${shopSlug ?? (query.shopSlug as string)}`;

  const pushCreateShow = async () => {
    await push(
      {
        pathname,
        query: {
          ...query,
          createShow: true
        }
      },
      `${baseShopPath}/shows/create`,
      {
        shallow: true
      }
    );
  };

  return { pushCreateShow };
}


export const useSplitScreen = () => {
  const [isSplitScreen, setIsSplitScreen] = useState(false);

  const checkIfSplitScreen = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (userAgent.includes('FBAN') || userAgent.includes('FBAV')) {
      setIsSplitScreen(window.innerHeight === window.screen.height);
    }
  };

  useEffect(() => {
    checkIfSplitScreen();
  }, []);

  return isSplitScreen;
};

