'use client';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { useRouter } from 'next/router';

const isProduction = process.env.NODE_ENV === 'production';

export function Insights() {
  const router = useRouter();
  return <SpeedInsights route={router.pathname} sampleRate={isProduction ? 1 : 0.5}  />;
}
