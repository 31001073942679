export class Gid extends URL {
  constructor(gidString: string) {
    super(gidString);
  }

  public static from(type: string, id: string) {
    return new Gid(`gid://jatango/${type}/${id}`);
  }

  public get id(): string {
    return this.href.split('/')[4];
  }

  public get type(): string {
    return this.href.split('/')[3];
  }
}

export function getId(gid: Gid | string) {
  if (typeof gid === 'string') {
    if (gid.includes('gid://')) {
      return new Gid(gid).id;
    }
    return gid;
  } else {
    return gid.id;
  }
}

export function toGidString(type: string, id: string) {
  if (id?.includes('gid://')) {
    const gid = new Gid(id);
    if (gid.type !== type) throw new Error(`GID type mismatch, expected '${type}', but got '${gid.type}'`);
    return id;
  }
  return Gid.from(type, id).toString();
}

export default Gid;
