import { useCallback, useState } from 'react';
import { QueryHookOptions, Reference } from '@apollo/client';
import { useRelayMutation } from '@/lib';
import {
  CreateProductMutation,
  CreateProductMutationVariables,
  CreateProductDocument,
  UpdateProductMutation,
  UpdateProductMutationVariables,
  UpdateProductDocument,
  PublishProductMutation,
  PublishProductMutationVariables,
  PublishProductDocument,
  UnpublishProductMutation,
  UnpublishProductMutationVariables,
  UnpublishProductDocument,
  DeleteProductImageMutation,
  DeleteProductImageMutationVariables,
  DeleteProductImageDocument,
  ShopProductsQuery,
  ShopProductsQueryVariables,
  ShopProductsDocument,
  useShopProductsQuery,
  ProductQuery,
  useProductQuery,
  ProductFragment,
  SearchProductsByShopQuery,
  useSearchProductsByShopQuery,
  useInventoryItemsByBarcodeQuery,
  ProductImageFragment,
  ProductImageFragmentDoc,
  AddProductImagesMutation,
  AddProductImagesMutationVariables,
  AddProductImagesDocument,
  AddOptionValueDocument,
  AddOptionValueMutation,
  AddOptionValueMutationVariables,
  OptionValueFragmentDoc,
  OptionValueFragment,
  AddVariantImagesMutation,
  AddVariantImagesMutationVariables,
  AddVariantImagesDocument,
  VariantImageFragmentDoc,
  VariantImageFragment,
  DeleteVariantImageMutation,
  DeleteVariantImageDocument,
  DeleteVariantImageMutationVariables,
  ArchiveProductMutation,
  ArchiveProductMutationVariables,
  ArchiveProductDocument,
  DeleteVariantMutation,
  DeleteVariantMutationVariables,
  DeleteVariantDocument,
  RemoveOptionValueMutation,
  RemoveOptionValueMutationVariables,
  RemoveOptionValueDocument,
  UpdateOptionMutation,
  UpdateOptionMutationVariables,
  UpdateOptionDocument,
  DeleteOptionMutation,
  DeleteOptionMutationVariables,
  DeleteOptionDocument,
  AddVariantOptionValueMutation,
  AddVariantOptionValueMutationVariables,
  AddVariantOptionValueDocument,
  RemoveVariantOptionValueMutation,
  RemoveVariantOptionValueDocument,
  RemoveVariantOptionValueMutationVariables,
  CreateOptionMutation,
  CreateOptionMutationVariables,
  CreateOptionDocument,
  OptionFragment,
  OptionFragmentDoc,
  useVariantQuery
} from '@/api';

export function useCreateProduct() {
  return useRelayMutation<CreateProductMutation, CreateProductMutationVariables, 'createProduct'>(
    CreateProductDocument,
    'createProduct',
    {
      update(cache, { data }) {
        if (data?.createProduct?.product) {
          const productsQuery = cache.readQuery<ShopProductsQuery, ShopProductsQueryVariables>({
            query: ShopProductsDocument,
            variables: {
              shopId: data.createProduct?.product.shopId
            }
          });

          if (productsQuery?.productsByShop) {
            cache.writeQuery<ShopProductsQuery, ShopProductsQueryVariables>({
              query: ShopProductsDocument,
              variables: {
                shopId: data.createProduct?.product.shopId
              },
              data: {
                ...productsQuery,
                productsByShop: {
                  ...productsQuery.productsByShop,
                  edges: [
                    { node: data.createProduct.product, cursor: '' },
                    ...(productsQuery.productsByShop.edges ?? [])
                  ]
                }
              }
            });
          }
        }
      }
    }
  );
}

export function useUpdateProduct() {
  return useRelayMutation<UpdateProductMutation, UpdateProductMutationVariables, 'updateProduct'>(
    UpdateProductDocument,
    'updateProduct'
  );
}

export function usePublishProduct() {
  return useRelayMutation<PublishProductMutation, PublishProductMutationVariables, 'publishProduct'>(
    PublishProductDocument,
    'publishProduct'
  );
}

export function useUnpublishProduct() {
  return useRelayMutation<UnpublishProductMutation, UnpublishProductMutationVariables, 'unpublishProduct'>(
    UnpublishProductDocument,
    'unpublishProduct'
  );
}

export function useArchiveProduct() {
  return useRelayMutation<ArchiveProductMutation, ArchiveProductMutationVariables, 'archiveProduct'>(
    ArchiveProductDocument,
    'archiveProduct',
    {
      update(cache, { data }) {
        if (data?.archiveProduct.product?.id) {
          cache.modify({
            fields: {
              productsByShop(previous: ShopProductsQuery['productsByShop'], { readField }) {
                return {
                  ...previous,
                  edges:
                    previous?.edges?.filter((e) => readField('id', e.node) !== data.archiveProduct.product?.id) ?? []
                };
              }
            }
          });
          cache.evict({
            id: data.archiveProduct.product.id
          });
        }
      }
    }
  );
}

export function useGetVariant(variantId: string) {
  const {data, ...rest} = useVariantQuery({
    variables: {
      variantId: variantId
    }
  });
  
  return { data: data, ...rest };
};

export function useDeleteVariant() {
  return useRelayMutation<DeleteVariantMutation, DeleteVariantMutationVariables, 'deleteVariant'>(
    DeleteVariantDocument,
    'deleteVariant',
    {
      update(cache, { data }) {
        if (data?.deleteVariant.variant?.id) {
          cache.evict({
            id: data.deleteVariant.variant.id
          });
        }
      }
    }
  );
}

export function useAddProductImages() {
  return useRelayMutation<AddProductImagesMutation, AddProductImagesMutationVariables, 'addProductImages'>(
    AddProductImagesDocument,
    'addProductImages',
    {
      update(cache, { data }) {
        if (data?.addProductImages.productImages) {
          const newProductImageRefs = data.addProductImages.productImages.map((productImage) =>
            cache.writeFragment<ProductImageFragment>({
              fragment: ProductImageFragmentDoc,
              fragmentName: 'ProductImage',
              id: productImage.id,
              data: productImage
            })
          );

          cache.modify({
            id: data.addProductImages.productImages[0].productId,
            fields: {
              defaultImage(existingImageRef: Reference, { toReference }) {
                if (!existingImageRef) {
                  return toReference({
                    __typename: 'Image',
                    id: data.addProductImages.productImages![0].imageId
                  });
                }
                return existingImageRef;
              },
              productImages(existingImages = { nodes: [] }) {
                return {
                  nodes: [
                    ...existingImages.nodes.slice(
                      0,
                      data.addProductImages.productImages![data.addProductImages.productImages!.length - 1].order
                    ),
                    ...newProductImageRefs,
                    ...existingImages.nodes.slice(
                      data.addProductImages.productImages![data.addProductImages.productImages!.length - 1].order +
                        newProductImageRefs.length
                    )
                  ]
                };
              }
            }
          });
        }
      }
    }
  );
}

export function useAddVariantImages() {
  return useRelayMutation<AddVariantImagesMutation, AddVariantImagesMutationVariables, 'addVariantImages'>(
    AddVariantImagesDocument,
    'addVariantImages',
    {
      update(cache, { data }) {
        if (data?.addVariantImages.variantImages) {
          const newVariantImagesRefs = data.addVariantImages.variantImages.map((variantImage) =>
            cache.writeFragment<VariantImageFragment>({
              fragment: VariantImageFragmentDoc,
              fragmentName: 'VariantImage',
              id: variantImage.id,
              data: variantImage
            })
          );

          cache.modify({
            id: data.addVariantImages.variantImages[0].variantId,
            fields: {
              defaultImage(existingImageRef: Reference, { toReference }) {
                if (!existingImageRef) {
                  return toReference({
                    __typename: 'Image',
                    id: data.addVariantImages.variantImages![0].imageId
                  });
                }
                return existingImageRef;
              },
              variantImages(existingImages = { nodes: [] }) {
                return {
                  nodes: [
                    ...existingImages.nodes.slice(
                      0,
                      data.addVariantImages.variantImages![data.addVariantImages.variantImages!.length - 1].order
                    ),
                    ...newVariantImagesRefs,
                    ...existingImages.nodes.slice(
                      data.addVariantImages.variantImages![data.addVariantImages.variantImages!.length - 1].order +
                        newVariantImagesRefs.length
                    )
                  ]
                };
              }
            }
          });
        }
      }
    }
  );
}

export function useDeleteProductImage() {
  return useRelayMutation<DeleteProductImageMutation, DeleteProductImageMutationVariables, 'deleteProductImage'>(
    DeleteProductImageDocument,
    'deleteProductImage',
    {
      update(cache, { data }) {
        if (data?.deleteProductImage.productImage) {
          cache.modify({
            id: data.deleteProductImage.productImage.productId,
            fields: {
              defaultImage(existingImageRef: Reference, { readField, DELETE }) {
                if (readField('id', existingImageRef) === data.deleteProductImage.productImage!.imageId) {
                  return DELETE;
                }
                return existingImageRef;
              },
              productImages(existingImages = { nodes: [] }, { readField }) {
                return {
                  nodes: existingImages.nodes.filter((imageRef: Reference) => {
                    return readField('imageId', imageRef) !== data.deleteProductImage.productImage!.imageId;
                  })
                };
              }
            }
          });
        }
      }
    }
  );
}

export function useDeleteVariantImage() {
  return useRelayMutation<DeleteVariantImageMutation, DeleteVariantImageMutationVariables, 'deleteVariantImage'>(
    DeleteVariantImageDocument,
    'deleteVariantImage',
    {
      update(cache, { data }) {
        if (data?.deleteVariantImage.variantImage) {
          cache.modify({
            id: data.deleteVariantImage.variantImage.variantId,
            fields: {
              defaultImage(existingImageRef: Reference, { readField, DELETE }) {
                if (readField('id', existingImageRef) === data.deleteVariantImage.variantImage!.imageId) {
                  return DELETE;
                }
                return existingImageRef;
              },
              variantImages(existingImages = { nodes: [] }, { readField }) {
                return {
                  nodes: existingImages.nodes.filter((imageRef: Reference) => {
                    return readField('imageId', imageRef) !== data.deleteVariantImage.variantImage!.imageId;
                  })
                };
              }
            }
          });
        }
      }
    }
  );
}

export function useAddOptionValue() {
  return useRelayMutation<AddOptionValueMutation, AddOptionValueMutationVariables, 'addOptionValue'>(
    AddOptionValueDocument,
    'addOptionValue',
    {
      update(cache, { data }) {
        if (data?.addOptionValue.optionValue) {
          const newOptionValueRef = cache.writeFragment<OptionValueFragment>({
            fragment: OptionValueFragmentDoc,
            fragmentName: 'OptionValue',
            id: data.addOptionValue.optionValue.id,
            data: data.addOptionValue.optionValue
          });

          cache.modify({
            id: data.addOptionValue.optionValue.optionId,
            fields: {
              optionValues(existingValues = { nodes: [] }) {
                return {
                  nodes: [...existingValues.nodes, newOptionValueRef]
                };
              }
            }
          });
        }
      }
    }
  );
}

export function useRemoveOptionValue() {
  return useRelayMutation<RemoveOptionValueMutation, RemoveOptionValueMutationVariables, 'removeOptionValue'>(
    RemoveOptionValueDocument,
    'removeOptionValue',
    {
      update(cache, { data }) {
        if (data?.removeOptionValue.optionValue) {
          cache.evict({
            id: data.removeOptionValue.optionValue.id
          });
        }
      }
    }
  );
}

export function useCreateOption() {
  return useRelayMutation<CreateOptionMutation, CreateOptionMutationVariables, 'createOption'>(
    CreateOptionDocument,
    'createOption',
    {
      update(cache, { data }) {
        if (data?.createOption.option) {
          const optionFragment = cache.writeFragment<OptionFragment>({
            fragment: OptionFragmentDoc,
            data: data?.createOption.option,
            fragmentName: 'Option'
          });
          cache.modify({
            id: data?.createOption.option?.productId!,
            fields: {
              options(value) {
                return {
                  ...value,
                  nodes: [...value.nodes, optionFragment]
                };
              }
            }
          });
        }
      }
    }
  );
}

export function useUpdateOption() {
  return useRelayMutation<UpdateOptionMutation, UpdateOptionMutationVariables, 'updateOption'>(
    UpdateOptionDocument,
    'updateOption'
  );
}

export function useDeleteOption() {
  return useRelayMutation<DeleteOptionMutation, DeleteOptionMutationVariables, 'deleteOption'>(
    DeleteOptionDocument,
    'deleteOption',
    {
      update(cache, { data }) {
        if (data?.deleteOption?.option) {
          cache.evict({
            id: data.deleteOption.option.id
          });
        }
      }
    }
  );
}

export function useAddVariantOptionValue() {
  return useRelayMutation<
    AddVariantOptionValueMutation,
    AddVariantOptionValueMutationVariables,
    'addVariantOptionValue'
  >(AddVariantOptionValueDocument, 'addVariantOptionValue');
}

export function useRemoveVariantOptionValue() {
  return useRelayMutation<
    RemoveVariantOptionValueMutation,
    RemoveVariantOptionValueMutationVariables,
    'removeVariantOptionValue'
  >(RemoveVariantOptionValueDocument, 'removeVariantOptionValue');
}

export function useShopProducts(shopId: string, options?: QueryHookOptions<ShopProductsQuery, any>) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useShopProductsQuery({
    variables: {
      shopId
    },
    ...options
  });
  const [fetchingMoreProducts, setFetchingMoreProducts] = useState<boolean>(false);

  const fetchMoreProducts = useCallback(async () => {
    setFetchingMoreProducts(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.productsByShop?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreProducts(false);
    }
  }, [data, fetchMoreFn, setFetchingMoreProducts]);

  return {
    products: (data?.productsByShop?.edges?.map((edge) => edge.node) ?? []) as ProductFragment[],
    hasMoreProducts: data?.productsByShop?.pageInfo.hasNextPage ?? false,
    fetchingMoreProducts,
    fetchMoreProducts,
    ...rest
  };
}

export function useSearchProductsByShop(
  shopId: string,
  query: string,
  options?: QueryHookOptions<SearchProductsByShopQuery, any>
) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useSearchProductsByShopQuery({
    fetchPolicy: 'network-only',
    variables: {
      shopId,
      query
    },
    ...options
  });
  const [fetchingMoreProductResults, setFetchingMoreProductResults] = useState<boolean>(false);

  const fetchMoreProductResults = useCallback(async () => {
    setFetchingMoreProductResults(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.searchProductsByShop?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreProductResults(false);
    }
  }, [data, setFetchingMoreProductResults]);

  return {
    productResults: (data?.searchProductsByShop?.edges?.map((edge) => edge.node) ?? []) as ProductFragment[],
    hasMoreProductResults: data?.searchProductsByShop?.pageInfo.hasNextPage ?? false,
    fetchingMoreProductResults,
    fetchMoreProductResults,
    ...rest
  };
}

export function useProduct(id: string, options?: QueryHookOptions<ProductQuery, any>) {
  const { data, ...rest } = useProductQuery({
    variables: {
      id
    },
    ...options
  });

  return { product: data?.product, ...rest };
}

export function useProductsByBarcode(shopId: string, barcode: string, skip?: boolean) {
  const { data, ...rest } = useInventoryItemsByBarcodeQuery({
    variables: {
      shopId,
      barcode
    },
    skip
  });

  const products: ProductFragment[] = data?.inventoryItemsByBarcode?.nodes?.map((i) => i.product) ?? [];

  return { products, ...rest };
}
